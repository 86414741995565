import path from 'path';

const debugI18n = ['true', 1].includes(
  process?.env?.NEXTJS_DEBUG_I18N ?? 'false'
);

// const localePublicFolder = undefined;

export const defaultLocale = 'en';

/**
 * @type {import('next-i18next').UserConfig}
 */
export default {
  i18n: {
    defaultLocale,
    locales: ['en', 'es', 'pt', 'it', 'de', 'fr'],
  },
  localePath: path.resolve('./public/locales/'),
  saveMissing: false,
  strictMode: true,
  serializeConfig: false,
  reloadOnPrerender: process?.env?.NODE_ENV === 'development',
  react: {
    useSuspense: false,
  },
  ns: ['translation'],
  defaultNS: 'translation',
  debug: debugI18n,
  /*
  interpolation: {
    escapeValue: false,
  },
  */
  // localePath:
  //   typeof window === 'undefined'
  //     ? path.resolve('../../packages/common-i18n/src/locales')
  //     : localePublicFolder,
};
